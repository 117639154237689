import React from "react";
import HeaderContent from "../Head/HeaderContent";
import BodyError from "./BodyError";

const ErrorNotFound = () => {
    return(
        <>
            <BodyError/>
        </>
    )
}
export default ErrorNotFound
